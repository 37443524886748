<template>
    <custom-drawer
        size="880px"
        :wrapper_closable="true"
        :visible.sync="visible_in_line"
        :confirm_to_close="false"
    >
        <template slot="title">
            <div class="flex items-center justify-between flex-1 h-full pr-5">
                <div>
                    <p class="text-sm font-semibold">
                        {{ $t('common.cpa_suggestion') }}
                    </p>
                </div>

                <!-- <el-button
                    icon="el-icon-check"
                    type="primary"
                    size="small"
                    :disabled="
                        m__loading || !adgroup_to_edit_budget_list.length
                    "
                    :loading="m__loading"
                    @click="handleConfirm()"
                >
                    {{ $t('button.confirm') }}
                </el-button> -->
            </div>
        </template>

        <div v-loading="m__loading" class="px-5 py-4">
            <!-- <el-alert
                v-if="!adgroup_to_edit_budget_list.length"
                type="warning"
                :closable="false"
                class="mb-2"
            >
                {{ $t('common.no_suitable_ad_groups_bulk_budget_changes') }}
            </el-alert> -->

            <div class="component-box">
                <div class="mb-4">
                    <div
                        class="flex space-x-[60px] border-border border-[1px] p-4 rounded-lg mx-auto"
                        :style="{ width: 'fit-content' }"
                    >
                        <div class="">
                            <p>CPA bé nhất</p>
                            <p class="text-brand-atosa--light mt-2 text-3xl">
                                {{
                                    p__renderMoneyByCurrency(
                                        result.min,
                                        currency
                                    )
                                }}
                            </p>
                        </div>
                        <div class="">
                            <p>CPA lớn nhất</p>
                            <p class="text-brand-atosa--light mt-2 text-3xl">
                                {{
                                    p__renderMoneyByCurrency(
                                        result.max,
                                        currency
                                    )
                                }}
                            </p>
                        </div>
                        <div class="">
                            <p>CPA trung bình</p>
                            <p class="text-brand-atosa--light mt-2 text-3xl">
                                {{
                                    p__renderMoneyByCurrency(
                                        result.avg,
                                        currency
                                    )
                                }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="flex space-x-4">
                    <label-outside-input-select
                        :label="$t('common.advertiser_account')"
                        bg="bg-transparent"
                    >
                        <el-select
                            v-model="selected_ads_accounts"
                            size="small"
                            filterable
                            :multiple="true"
                            :placeholder="
                                $t('input.placeholder.select_ads_account')
                            "
                            class="w-[300px]"
                        >
                            <el-option-group
                                :label="$t('common.advertiser_account')"
                            >
                                <el-option
                                    v-for="item in ads_accounts"
                                    :key="item.advertiser_id"
                                    :label="item.name"
                                    :value="item.advertiser_id"
                                >
                                    <div class="relative">
                                        <div
                                            v-if="item.belong_to_atosa"
                                            class="-right-4 bg-brand-atosa--light util-flex-center absolute bottom-0 h-5 px-1 text-white"
                                        >
                                            <span class="text-xxxs uppercase">
                                                atosa
                                            </span>
                                        </div>
                                        <div class="flex flex-col py-1">
                                            <span
                                                class="text-xs font-semibold leading-5"
                                            >
                                                {{ item.name }}
                                            </span>
                                            <div
                                                class="flex items-center space-x-1 leading-3"
                                            >
                                                <i class="el-icon-money"></i>

                                                <span class="text-xs">
                                                    {{
                                                        p__renderMoneyByCurrency(
                                                            item.balance,
                                                            item.currency
                                                        )
                                                    }}
                                                </span>
                                            </div>
                                            <span class="component-text-desc">
                                                #{{ item.advertiser_id }}
                                            </span>
                                        </div>
                                    </div>
                                </el-option>
                            </el-option-group>
                        </el-select>
                    </label-outside-input-select>
                </div>

                <el-date-picker
                    v-model="ranger_date"
                    class="!w-[300px] mt-4"
                    size="small"
                    popper-class="custom-el-date"
                    type="daterange"
                    unlink-panels
                    :start-placeholder="$t('date_picker.placeholder.start')"
                    :end-placeholder="$t('date_picker.placeholder.end')"
                    :picker-options="m__picker_options"
                    :format="$t('common.format_value_date_picker')"
                    value-format="yyyy-MM-dd"
                >
                </el-date-picker>

                <div class="flex mt-4">
                    <div class="flex-1">
                        <p class="text-xs">ROAS</p>
                        <div class="flex space-x-2">
                            <el-input-number
                                v-model="condition.onsite_shopping.min"
                                class="w-[160px]"
                                size="small"
                                :min="0"
                            ></el-input-number>
                            <span>-</span>
                            <el-input-number
                                v-model="condition.onsite_shopping.max"
                                class="w-[160px]"
                                size="small"
                                :min="0"
                            ></el-input-number>
                        </div>
                    </div>
                    <div class="flex-1">
                        <p class="text-xs">Lượt mua hàng (mua sắm)</p>
                        <div class="flex space-x-2">
                            <el-input-number
                                v-model="condition.initiate_checkout.min"
                                class="w-[160px]"
                                size="small"
                                :min="0"
                            ></el-input-number>

                            <span>-</span>

                            <el-input-number
                                v-model="condition.initiate_checkout.max"
                                class="w-[160px]"
                                size="small"
                                :min="0"
                            ></el-input-number>
                        </div>
                    </div>
                </div>

                <div class="flex mt-4">
                    <div class="flex-1">
                        <p class="text-xs">Lượt nhấp chuột</p>
                        <div class="flex space-x-2">
                            <el-input-number
                                v-model="condition.clicks.min"
                                class="w-[160px]"
                                size="small"
                                :min="0"
                            ></el-input-number>

                            <span>-</span>

                            <el-input-number
                                v-model="condition.clicks.max"
                                class="w-[160px]"
                                size="small"
                                :min="0"
                            ></el-input-number>
                        </div>
                    </div>

                    <div class="flex-1">
                        <p class="text-xs">Lượt chuyển đổi</p>

                        <div class="flex space-x-2">
                            <el-input-number
                                v-model="condition.conversion.min"
                                class="w-[160px]"
                                size="small"
                                :min="0"
                            ></el-input-number>

                            <span>-</span>

                            <el-input-number
                                v-model="condition.conversion.max"
                                class="w-[160px]"
                                size="small"
                                :min="0"
                            ></el-input-number>
                        </div>
                    </div>
                </div>

                <div class="flex mt-4">
                    <div class="flex-1">
                        <p class="text-xs">Số lần hiển thị</p>

                        <div class="flex space-x-2">
                            <el-input-number
                                v-model="condition.impressions.min"
                                class="w-[160px]"
                                size="small"
                                :min="0"
                            ></el-input-number>
                            <span>-</span>

                            <el-input-number
                                v-model="condition.impressions.max"
                                class="w-[160px]"
                                size="small"
                                :min="0"
                            ></el-input-number>
                        </div>
                    </div>

                    <div class="flex-1">
                        <p class="text-xs">Mục tiêu tối ưu hóa</p>

                        <el-select
                            v-model="optimization_goal_filter"
                            size="small"
                            class="w-[320px]"
                            placeholder="Select"
                            :multiple="true"
                        >
                            <el-option
                                v-for="item in optimization_goal_options"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>

                <div class="flex mt-4">
                    <div class="flex-1">
                        <p class="text-xs">Loại nhóm quảng cáo</p>

                        <el-select
                            v-model="ad_entity_type_filter"
                            size="small"
                            class="w-[320px]"
                            placeholder="Select"
                            :multiple="true"
                        >
                            <el-option
                                v-for="item in ad_entity_type_options"
                                :key="item.value"
                                :label="item.title"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>

                <!-- <div class="mt-4">
                    <el-table
                        ref="multipleTable"
                        class="campaign-table"
                        max-height="560"
                        :data="m__data_for_pagination"
                        @selection-change="handleSelectionChange"
                    >
                        <el-table-column
                            type="selection"
                            :reserve-selection="true"
                            width="40"
                            :fixed="true"
                        >
                        </el-table-column>

                        <el-table-column
                            min-width="360"
                            prop="campaign.campaign_name"
                            :label="$t('common.campaign')"
                            :fixed="true"
                        >
                            <template #default="scope">
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="scope.row.campaign.campaign_name"
                                    placement="top"
                                >
                                    <p
                                        class="hover:underline truncate max-w-[240px]"
                                    >
                                        {{ scope.row.campaign.campaign_name }}
                                    </p>
                                </el-tooltip>
                            </template>
                        </el-table-column>

                        <el-table-column
                            width="160"
                            :show-overflow-tooltip="true"
                            label="ID"
                        >
                            <template #default="scope">
                                <p>
                                    {{ scope.row.id }}
                                </p>
                            </template>
                        </el-table-column>

                        <el-table-column
                            width="160"
                            :show-overflow-tooltip="true"
                            prop="campaign.objective_type"
                            :label="$t('page.campaign.objective_type')"
                        >
                            <template #default="scope">
                                <p>
                                    {{
                                        p__formatObjectiveType(
                                            scope.row.campaign.objective_type
                                        )
                                    }}
                                </p>
                            </template>
                        </el-table-column>
                    </el-table>
                </div> -->

                <el-button
                    size="small"
                    type="primary"
                    class="mt-4"
                    :disabled="selected_ads_accounts.length === 0"
                    @click="findCpa()"
                >
                    Tìm CPA
                </el-button>
            </div>
        </div>
    </custom-drawer>
</template>

<script>
import rangerDateMixin from '@/plugins/mixins/ranger-date'
import { getReportCampaigns } from '@/services/atosa-tiktok-ads/reporting'
import paginationDataMixin from '@/plugins/mixins/pagination-data'

export default {
    mixins: [rangerDateMixin, paginationDataMixin],

    props: [
        'visible',
        'advertiser_id',
        'belong_to_atosa',
        'tiktok_account_id',
        'ads_accounts'
    ],

    data() {
        return {
            ad_entity_type_filter: [],
            selected_ads_accounts: [],
            optimization_goal_filter: [],
            ad_entity_type_options: [
                {
                    title: this.$t('page.campaign.video_shopping'),
                    value: 'VIDEO_SHOPPING_ADS'
                },
                {
                    title: this.$t('page.campaign.live_shopping'),
                    value: 'LIVE_SHOPPING_ADS'
                },
                {
                    title: this.$t('page.campaign.product_shopping_ads'),
                    value: 'PRODUCT_SHOPPING_ADS'
                }
            ],
            optimization_goal_options: [
                {
                    value: 'CLICK',
                    title: this.$t('common.click')
                },
                {
                    value: 'CONVERT',
                    title: this.$t('common.conversion')
                },
                {
                    value: 'INSTALL',
                    title: this.$t('page.campaign.install')
                },
                {
                    value: 'IN_APP_EVENT',
                    title: this.$t('page.campaign.in_app_event')
                },
                {
                    value: 'SHOW',
                    title: this.$t('common.impression')
                },
                {
                    value: 'VIDEO_VIEW',
                    title: this.$t('page.campaign.video_view')
                },
                {
                    value: 'REACH',
                    title: this.$t('common.reach')
                },
                {
                    value: 'LEAD_GENERATION',
                    title: this.$t(
                        'page.campaign.optimization_goal_lead_generation'
                    )
                },
                {
                    value: 'FOLLOWERS',
                    title: this.$t('page.campaign.followers')
                },
                {
                    value: 'VALUE',
                    title: this.$t('page.campaign.optimization_goal_gmv')
                },
                {
                    value: 'GMV',
                    title: 'GMV'
                },
                {
                    value: 'MT_LIVE_ROOM',
                    title: this.$t('page.campaign.viewer_retention')
                },
                {
                    value: 'PRODUCT_CLICK_IN_LIVE',
                    title: this.$t('page.campaign.product_click_in_live')
                },
                {
                    value: 'ENGAGED_VIEW',
                    title: this.$t('page.campaign.column_engaged_view')
                },
                {
                    value: 'ENGAGED_VIEW_FIFTEEN',
                    title: this.$t('page.campaign.column_engaged_view_15s')
                },
                {
                    value: 'TRAFFIC_LANDING_PAGE_VIEW',
                    title: this.$t('page.campaign.traffic_landing_page_view')
                }
            ],
            currency: 'VND',
            show_result: false,
            result: {
                min: 0,
                max: 0,
                avg: 0
            },
            multiple_selection: [],
            ranger_date: this.p__getRangerDateFromThen(30),
            condition: {
                onsite_shopping: {
                    min: 0,
                    max: 0
                },
                initiate_checkout: {
                    min: 0,
                    max: 0
                },
                clicks: {
                    min: 0,
                    max: 0
                },
                impressions: {
                    min: 0,
                    max: 0
                },
                conversion: {
                    min: 0,
                    max: 0
                }
            },
            metrics: [
                ...this.p__basic_metrics,
                'initiate_checkout',
                'onsite_shopping'
            ]
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    watch: {
        ranger_date() {
            this.fetchOverviewReport()
        },

        advertiser_id() {
            this.selected_ads_accounts = [this.advertiser_id]

            this.fetchOverviewReport()
        }
    },

    mounted() {
        this.fetchOverviewReport()
    },

    methods: {
        handleSelectionChange(val) {
            this.multiple_selection = val
        },

        async fetchOverviewReport() {
            if (!this.advertiser_id) {
                return
            }

            this.m__loading = true

            try {
                const start_date = this.ranger_date[0]
                const end_date = this.ranger_date[1]

                const data = {
                    page: 1,
                    page_size: 100,
                    data_level: 'AUCTION_CAMPAIGN',
                    metrics: this.metrics,
                    start_date,
                    end_date,
                    type: 'campaign_id',
                    advertiser_id: this.advertiser_id,
                    dimensions: ['campaign_id'],
                    report_type: 'BASIC'
                }

                const response = await getReportCampaigns(
                    this.tiktok_account_id,
                    data
                )

                if (response.code === 0 && response.data.list.length > 0) {
                    this.m__data_for_pagination = response.data.list
                }
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        async findCpa() {
            if (this.selected_ads_accounts.length === 0) {
                return
            }

            this.result = {
                min: 0,
                max: 0,
                avg: 0
            }

            this.m__loading = true

            try {
                let temp_filter = []

                const start_date = this.ranger_date[0]
                const end_date = this.ranger_date[1]

                const temp = []

                for (const advertiser_id of this.selected_ads_accounts) {
                    const data = {
                        page: 1,
                        page_size: 100,
                        data_level: 'AUCTION_ADGROUP',
                        metrics: this.metrics,
                        start_date,
                        end_date,
                        type: 'adgroup_id',
                        advertiser_id,
                        dimensions: ['adgroup_id'],
                        report_type: 'BASIC',
                        filtering: [
                            {
                                filter_value: JSON.stringify(
                                    this.optimization_goal_filter
                                ),
                                field_name: 'optimization_goal',
                                filter_type: 'IN'
                            },
                            {
                                filter_value: JSON.stringify(
                                    this.ad_entity_type_filter
                                ),
                                field_name: 'ad_entity_type',
                                filter_type: 'IN'
                            }
                        ]
                    }

                    const response = await getReportCampaigns(
                        this.tiktok_account_id,
                        data
                    )

                    if (response.code === 0 && response.data.list.length > 0) {
                        temp.push(...response.data.list)

                        let total_page = response.data.page_info.total_page

                        if (total_page > 1) {
                            const array = []

                            for (let i = 2; i <= total_page; i++) {
                                array.push(i)
                            }

                            await Promise.all(
                                array.map(async (page) => {
                                    const data = {
                                        page,
                                        page_size: 100,
                                        data_level: 'AUCTION_ADGROUP',
                                        metrics: this.metrics,
                                        start_date,
                                        end_date,
                                        type: 'adgroup_id',
                                        advertiser_id,
                                        dimensions: ['adgroup_id'],
                                        report_type: 'BASIC',
                                        filtering: [
                                            {
                                                filter_value: JSON.stringify(
                                                    this
                                                        .optimization_goal_filter
                                                ),
                                                field_name: 'optimization_goal',
                                                filter_type: 'IN'
                                            },

                                            {
                                                filter_value: JSON.stringify(
                                                    this.ad_entity_type_filter
                                                ),
                                                field_name: 'ad_entity_type',
                                                filter_type: 'IN'
                                            }
                                        ]
                                    }

                                    const response = await getReportCampaigns(
                                        this.tiktok_account_id,
                                        data
                                    )

                                    if (
                                        response.code === 0 &&
                                        response.data.list.length > 0
                                    ) {
                                        temp.push(...response.data.list)
                                    }

                                    total_page =
                                        response.data.page_info.total_page
                                })
                            )
                        }
                    }
                }

                if (this.multiple_selection.length) {
                    temp_filter = temp.filter((item) =>
                        this.multiple_selection.some(
                            (camp) =>
                                camp.campaign.campaign_id ===
                                item.adgroup.campaign_id
                        )
                    )
                } else {
                    temp_filter = temp
                }

                if (this.condition.onsite_shopping.max > 0) {
                    temp_filter = temp_filter.filter(
                        (item) =>
                            item.onsite_shopping >=
                                this.condition.onsite_shopping.min &&
                            item.onsite_shopping <=
                                this.condition.onsite_shopping.max
                    )
                }

                if (this.condition.initiate_checkout.max > 0) {
                    temp_filter = temp_filter.filter(
                        (item) =>
                            item.initiate_checkout >=
                                this.condition.initiate_checkout.min &&
                            item.initiate_checkout <=
                                this.condition.initiate_checkout.max
                    )
                }

                if (this.condition.clicks.max > 0) {
                    temp_filter = temp_filter.filter(
                        (item) =>
                            item.clicks >= this.condition.clicks.min &&
                            item.clicks <= this.condition.clicks.max
                    )
                }

                if (this.condition.impressions.max > 0) {
                    temp_filter = temp_filter.filter(
                        (item) =>
                            item.impressions >=
                                this.condition.impressions.min &&
                            item.impressions <= this.condition.impressions.max
                    )
                }

                if (this.condition.conversion.max > 0) {
                    temp_filter = temp_filter.filter(
                        (item) =>
                            item.conversion >= this.condition.conversion.min &&
                            item.conversion <= this.condition.conversion.max
                    )
                }

                temp_filter = temp_filter.filter(
                    (item) => item.cost_per_conversion > 0
                )

                if (temp_filter.length > 0) {
                    if (temp_filter.length === 1) {
                        const first_item = temp_filter[0]

                        this.result = {
                            min: first_item.cost_per_conversion,
                            max: first_item.cost_per_conversion,
                            avg: first_item.cost_per_conversion
                        }
                    } else {
                        const min = Math.min(
                            ...temp_filter.map(
                                (item) => +item.cost_per_conversion
                            )
                        )
                        const max = Math.max(
                            ...temp_filter.map(
                                (item) => +item.cost_per_conversion
                            )
                        )

                        const sum_spend = temp_filter.reduce(
                            (acc, obj) => acc + +obj.spend,
                            0
                        )

                        const sum_conversion = temp_filter.reduce(
                            (acc, obj) => acc + +obj.conversion,
                            0
                        )
                        let avg = 0
                        if (sum_conversion > 0) {
                            avg =
                                sum_spend / sum_conversion
                        }

                        this.result = {
                            min: Math.round(+min * 1.3),
                            max: Math.round(+max * 1.3),
                            avg: Math.round(avg * 1.3)
                        }
                    }
                }

                this.show_result = true
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        }
    }
}
</script>
